<template>
  <div :class="CSSClasses" :style="computedStyle" v-show="!isHidden">
    <el-dropdown @command="clickMenu" :style="'width:' + width" :size="size">
      <el-button :type="type" :plain="plain" :round="round" :style="computedStyle" :disabled="isDisabled" >
        {{label}}<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown" >
        <template v-for="(item, index) of computedMenu" >
          <el-tooltip effect="dark" :key="index" :content="item.tooltip" placement="right" v-if="item.tooltip">
            <el-dropdown-item :icon="item.icon" :key="index" :command="item" :disabled="_isReadonly && !item.isAllow">{{item.name}}</el-dropdown-item>
          </el-tooltip>
          <el-dropdown-item v-else :icon="item.icon" :key="index" :command="item" :disabled="_isReadonly && !item.isAllow">{{item.name}}</el-dropdown-item>
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import RegistryCard from '@/components/RegistryCard'
import registryMixin from '../registry/registry_mixins'
import Dashboard from '@/components/Dashboard'

import ActionExecutor from '@/core/infrastructure/service/ActionExecutor'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'
// import PluginFactory from '@/core/infrastructure/service/PluginFactory'
export default {
  name: 'a-dropdown',
  components: {
    Dashboard,
    RegistryCard
  },
  inject: {
    getParentContext: {
      default: () => {}
    },
    addMainTab: {
      default: () => {}
    },
    openRegistryCard: {
      default: () => {}
    },
    openDashboardCard: {
      default: () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },
    // зависимости от a-button
    getParentDashboard: {
      default: () => {}
    },
    getViewer: {
      default: () => {
        return {}
      }
    },
    updateTab: {
      default: () => {}
    },
    tabs: {
      default: () => {}
    },
    activeTab: {
      default: () => {}
    },
    closeTab: {
      default: () => {}
    },
    openedCards: {
      default: () => {}
    },
    cancelChanges: {
      default: () => {}
    },
    openTabModalWindow: {
      default: () => {}
    },
    getContainersStore: {
      default: () => () => {}
    },
    getInterfaceWrapper: {
      default: () => () => {}
    }
  },
  mixins: [mixin, registryMixin, refreshComponentsMixin],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    label: {
      type: String,
      description: 'name'
    },
    type: {
      type: String,
      description: 'view',
      editor: 'Select',
      options: {
        multiple: false,
        options: [
          { id: 'primary', name: 'Primary' },
          { id: 'success', name: 'Success' },
          { id: 'info', name: 'Info' },
          { id: 'warning', name: 'Warning' },
          { id: 'danger', name: 'Danger' },
          { id: 'text', name: 'Text' }
        ],
        clearable: true
      }
    },
    plain: {
      type: Boolean,
      description: 'light_background',
      hidden: false
    },
    round: {
      type: Boolean,
      description: 'rounded_corner'
    },
    downMenu: {
      type: Array,
      description: 'edit_items',
      editor: 'DropDownMenu',
      default: () => []
    },
    isRequired: {
      frozen: true
    }
  },
  data () {
    return {
      downItems: []
    }
  },
  computed: {
    isDisabled () {
      if (this._isReadonly) {
        return this.downItems.filter((item) => item.isAllow).length === 0
      }
      return false
    },
    computedMenu () {
      return this.downItems
        .map(item => { return { ...item, isHide: this.checkConditions(item.isHidden) } })
        .filter(item => !item.isHide)
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (!this.block) {
        css += ';display: inline-block'
      }

      return css
    },
    inCard () {
      return typeof this.getCard === 'function'
    }
  },
  watch: {
    type: {
      handler (value) {
        const plain = this.getProperties()['plain']
        // Показывать только если выбран "Тип"
        this.$set(plain, 'hidden', !value)

        this.forceUpdateSettingsPanel()
      },
      immediate: true
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  mounted () {
    if (this.downMenu) {
      this.downItems = this.downMenu
    }
  },
  methods: {
    dataFilters (filter) {
      let filters = []
      if (filter) {
        filter.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              filters.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push({
              key: item.alias,
              value: item.attribute
            })
          }
        })
      }
      return filters
    },
    getDefaultsForCard (command) {
      let defaults = []
      if (command.card.defaults) {
        command.card.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    },
    async clickMenu (command, event) {
      console.log({ command })
      let readonly = command.isAllow ? !command.isAllow : this._isReadonly
      try {
        await ActionExecutor.execute(this, { readonly: readonly, pluginName: command.plugin, action: command, event: event })
      } catch (error) {
        console.error('Ошибка в действии', error)
      }
      // let me = this
      // if (command.type === 'execute_plugin') {
      //   if (!command.plugin) {
      //     console.log(`Plugin doesn't set`)
      //     return false
      //   }
      //   let plugin = await PluginFactory.build(this.$config.project, command.plugin, this)
      //   plugin.execute()
      // } else if (command.type === 'open_dashboard' && command.dashboard.id) {
      //   if (command.dashboard.isFullscreen) {
      //     this.openDashboardCard(command.dashboard.id, command.dashboard.window_title || '')
      //   } else {
      //     if (command.dashboard.frameGuid) {
      //       let frame = (this.getDashboardComponents()[`component_${command.dashboard.frameGuid}`] || [])[0]
      //       if (!frame) {
      //         console.warn('frame not found', command.dashboard)
      //         return false
      //       }

      //       frame.openDashboard({
      //         dashboardId: command.dashboard.id,
      //         title: command.dashboard.window_title
      //       })
      //       return
      //     }
      //     const h = this.$createElement
      //     let customClass = 'custom_scrollbar '
      //     if (command.dashboard.window_width) {
      //       customClass += `dashboard_window_width_${command.dashboard.window_width}`
      //     }
      //     this.$msgbox({
      //       title: command.dashboard.window_title,
      //       customClass: customClass,
      //       message: h('dashboard', {
      //         props: {
      //           id: command.dashboard.id,
      //           parentContext: this,
      //           model: JSON.parse(JSON.stringify(this.getModel())),
      //           msgbox: 'msgbox'
      //         },
      //         key: this.generateGuid()
      //       }),
      //       showCancelButton: false,
      //       showConfirmButton: false,
      //       closeOnClickModal: false
      //     })
      //   }
      // } else if (command.type === 'open_report' && command.reports.id) {
      //   let filter = this.dataFilters(command.filters)
      //   console.log(filter)
      //   this.addMainTab({ name: command.reports.name,
      //     componentType: 'StimulsoftViewer',
      //     payload: {
      //       filename: `${command.reports.guid}.mrt`,
      //       variables: filter
      //     } })
      // } else if (command.type === 'execute_command' && command.command.id) {
      //   this.getCard().saveRecord({ commandId: command.command.id }).then((response) => {
      //     this.$message({
      //       showClose: true,
      //       dangerouslyUseHTMLString: true,
      //       message: command.command.success_text,
      //       type: 'success'
      //     })
      //   }).catch((error) => {
      //     console.error(error)
      //     this.$message({
      //       showClose: true,
      //       dangerouslyUseHTMLString: true,
      //       message: command.command.failure_text,
      //       type: 'error'
      //     })
      //   })
      //   let depth

      //   switch (command.command?.close_type) {
      //     case 'current_card':
      //       depth = 1
      //       break
      //     case 'parent_card':
      //       depth = 2
      //       break
      //   }

      //   if (!('is_card_close' in command.command) || !command.command.is_card_close) {
      //     return
      //   }

      //   if (this.activeTab != null) {
      //     if (this.openedCards == null) {
      //       this.closeTab(this.activeTab())
      //     } else {
      //       if (depth) {
      //         this.cancelChanges({ depth: depth })
      //       }
      //     }
      //   } else {
      //     this.$msgbox.close()
      //   }
      // } else if (command.type === 'open_card') {
      //   if (
      //     !command.card.registryId ||
      //     !command.card.type ||
      //     (!command.card.fieldId && !command.card.constantRecordId && command.card.type !== 'add') ||
      //     !command.card.cardId
      //   ) {
      //     console.warn('wrong parameters', command.card)
      //     return false
      //   }
      //   const readOnly = command.card.type === 'read'
      //   let recordId = null
      //   let initialData = {}
      //   let defaults = this.getDefaultsForCard(command)
      //   defaults.forEach((item) => {
      //     initialData[item.key] = item.value
      //   })
      //   if (command.card.type === 'update' || command.card.type === 'read') {
      //     if (command.card.constantRecordId) {
      //       recordId = command.card.constantRecordId
      //     } else {
      //       recordId = this.getModel()[command.card.fieldId]
      //     }
      //     if (!recordId) {
      //       console.warn(`recordId doesn't set = ${command.card.fieldId}, constant = ${command.card.constantRecordId}`)
      //       return false
      //     }
      //   } else if (command.card.type === 'add') {
      //     if (command.card.fieldId) {
      //       if (!this.getModel()['id']) {
      //         console.warn(`record has not id`)
      //         return false
      //       }
      //       let fieldId = command.card.fieldId
      //       let outerXrefComponent = Object.values(this.getDashboardComponents()).find(function (item) {
      //         return item[0].name === fieldId
      //       })

      //       if (!outerXrefComponent || !outerXrefComponent[0].outerXrefId) {
      //         console.warn(`outer_xref does't set`)
      //         return false
      //       }

      //       initialData[`attr_${outerXrefComponent[0].outerXrefId}_`] = this.getModel()['id']
      //     }
      //     if (command.card.isFastCard) {
      //       this.openQuickAddCardByCheckbox(command.card, initialData)
      //       return false
      //     }
      //   }
      //   if (command.card.isWindow) {
      //     const h = this.$createElement
      //     let customClass = 'custom_scrollbar '
      //     if (command.card.windowWidth) {
      //       customClass += `dashboard_window_width_${command.card.windowWidth}`
      //     }
      //     this.$msgbox({
      //       title: command.card.windowTitle,
      //       customClass: customClass,
      //       message: h('registry-card', {
      //         style: {
      //           height: command.card?.windowHeight || ''
      //         },
      //         props: {
      //           cardId: command.card.cardId,
      //           registryId: command.card.registryId,
      //           readonly: readOnly,
      //           recordId: recordId,
      //           initialData: initialData
      //         },
      //         on: {
      //           cancelChanges () {
      //             me.$msgbox.close()
      //           },
      //           recordAdded (recordId) {
      //             me.refreshComponents(command.card.componentsGuid)
      //             me.$set(me.getModel(), command.card.fieldId, recordId)
      //           },
      //           recordEdited (recordId) {
      //             me.refreshComponents(command.card.componentsGuid)
      //             if (command.card.fieldId) {
      //               const selectField = Object.values(me.getDashboardComponents()).find(item => item[0].name === command.card.fieldId)
      //               if (selectField) {
      //                 if (typeof selectField[0].reloadById === 'function') {
      //                   selectField[0].reloadById(recordId)
      //                 } else if (typeof selectField[0].loadData === 'function') {
      //                   selectField[0].loadData()
      //                 }
      //               }
      //             }
      //           }
      //         },
      //         key: this.generateGuid() }),
      //       showCancelButton: false,
      //       showConfirmButton: false,
      //       closeOnClickModal: false
      //     })
      //   } else {
      //     this.openRegistryCard({
      //       registryId: command.card.registryId,
      //       cardId: command.card.cardId,
      //       cardName: '',
      //       recordId: recordId,
      //       initialData: initialData,
      //       registry: {
      //         readonly: readOnly,
      //         addRecord: () => {
      //           me.refreshComponents(command.card.componentsGuid)
      //         },
      //         updateRecord: () => {
      //           me.refreshComponents(command.card.componentsGuid)
      //         }
      //       }
      //     })
      //   }
      // } else if ((command.type === 'open_document' || command.type === 'open_xml') && command.reports.id) {
      //   let filters = this.dataFilters(command.filters)
      //   this.$http
      //     .request({
      //       method: 'post',
      //       url: `${this.$config.api}/reporteditor/reports/${command.reports.id}/document/${command.reports.formatType}`,
      //       data: {
      //         registry_id: command.reports.registryId,
      //         field_id: command.reports.assocFieldId,
      //         record_id: this.getModel()['id'],
      //         record_guid: this.getModel()['guid'],
      //         is_open_saved_version: command.reports.openSavedVersion,
      //         filters: filters
      //       },
      //       responseType: 'json'
      //     })
      //     .then((response) => {
      //       if (response.status !== 200) {
      //         return
      //       }

      //       if (command.reports.viewType === 'download') {
      //         if (command.reports.formatType === 'xml') {
      //           const url = window.URL.createObjectURL(new Blob([response.data.content], { type: 'application/xml' }))
      //           const link = document.createElement('a')
      //           link.href = url
      //           link.setAttribute('download', `${command.reports.name}.${command.reports.formatType}`)
      //           document.body.appendChild(link)
      //           link.click()
      //           link.remove()
      //         } else {
      //           this.$http
      //             .request({
      //               method: 'post',
      //               url: `${this.$config.api}/reporteditor/reports/document/download`,
      //               data: response.data,
      //               responseType: 'blob'
      //             })
      //             .then(res => {
      //               let formats = {
      //                 docx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //                 pdf: 'application/pdf'
      //               }
      //               const url = window.URL.createObjectURL(new Blob([res.data], { type: formats[command.reports.formatType] }))
      //               const link = document.createElement('a')
      //               link.href = url
      //               link.setAttribute('download', `${command.reports.name}.${command.reports.formatType}`)
      //               document.body.appendChild(link)
      //               link.click()
      //               link.remove()
      //             })
      //         }
      //       } else {
      //         if (command.reports.formatType !== 'xml') {
      //           this.addMainTab({ name: command.reports.name,
      //             componentType: 'DocumentViewer',
      //             payload: {
      //               guid: this.generateGuid(),
      //               filename: `${response.data.name}.${response.data.extension}`,
      //               isRegistryFile: response.data.is_registry_file,
      //               reportId: command.reports.id
      //             } })
      //         } else {
      //           this.addMainTab({ name: command.reports.name,
      //             componentType: 'XmlViewer',
      //             payload: {
      //               guid: this.generateGuid(),
      //               content: response.data.content
      //             } })
      //         }
      //       }

      //       console.log('Build and opening document successful is completed!')
      //     })
      // } else if (command.type === 'etl_export') {
      //   this.$http({
      //     method: 'post',
      //     url: `${this.$config.api}/etleditor/export/${command.etl_export.task_id}`,
      //     responseType: 'arraybuffer'
      //   })
      //     .then(response => {
      //       let blob = new Blob([response.data], { type: response.headers['content-type'] })
      //       let url = window.URL.createObjectURL(blob)
      //       window.open(url)
      //     })
      // } else if (command.type === 'open_url') {
      //   const openType = command.url.openType
      //   let url = command.url.url

      //   try {
      //     // Проверить корректность URL
      //     const temp = new URL(url)
      //   } catch (error) {
      //     // URL без протокола
      //     if (url.substring(0, 2) !== '//') {
      //       // Сделать URL абсолютным
      //       url = '//' + url
      //     }
      //   }

      //   if (openType === 'newTab') {
      //     window.open(url, '_blank')
      //     return
      //   }
      //   if (openType === 'currentTab') {
      //     window.open(url, '_self')
      //     return
      //   }
      //   if (openType === 'newWindow') {
      //     const win = window.open(url, url, 'menubar=yes,toolbar=yes,location=yes,status=yes,resizable=yes')
      //   }
      // } else if (command.type === 'open_window') {
      //   if (command.window?.guid) {
      //     const viewer = this.getViewer()
      //     if (!viewer) {
      //       console.warn('viewer not found', this)
      //       return false
      //     }
      //     const _window = viewer.windows.find((item) => item.guid === command.window?.guid)
      //     if (!_window) {
      //       console.warn(`window guid = ${command.window?.guid} - not found in windows`, viewer.windows)
      //       return false
      //     }
      //     viewer.modalWindow.isPopover = command.window.popover
      //     if (viewer.modalWindow.isPopover) {
      //       viewer.modalWindow.position = {
      //         x: event.clientX,
      //         y: event.clientY
      //       }
      //     }
      //     if (typeof this.getCard === 'function') {
      //       const card = this.getCard()
      //       if (card) {
      //         let components = []
      //         for (let key in _window.structure.components) {
      //           if (_window.structure.components.hasOwnProperty(key)) {
      //             components.push(_window.structure.components[key])
      //           }
      //         }
      //         components.forEach((item) => {
      //           const initialType =
      //         typeof item.group !== 'undefined'
      //           ? `${item.group}/${item.initialType}` : item.initialType
      //           if (!card.data[item.properties.name] && /attr_[0-9]+_/i.test(item.properties.name)) {
      //             if (!(item.properties.name in card.initialData) && !(item.properties.name in card.recordData) && initialType !== 'registry/xref_outer_field') {
      //               console.warn(`Не найдены данные в записи по компоненту с атрибутом = ${item.properties.name}`)
      //             }
      //             card.$set(card.data, item.properties.name, card.initialData[item.properties.name] || card.recordData[item.properties.name])
      //             if (card.excludingComponents.includes(initialType)) {
      //               card.excludingAttributes.push(item.properties.name)
      //             }
      //             if (initialType === 'basic/a-file' && item.properties.name) {
      //               card.fileAttributes.push(item.properties.name)
      //             }
      //           }
      //         })
      //       }
      //     }
      //     viewer.modalWindow.show = true
      //     viewer.modalWindow.model = this.getModel()
      //     viewer.modalWindow.width = `${_window.width.value}${_window.width.type}`
      //     viewer.modalWindow.height = `${_window.height.value}${_window.height.type}`
      //     viewer.modalWindow.title = undefined
      //     if (command.window?.title) {
      //       viewer.modalWindow.title = command.window.title
      //     }
      //     viewer.$nextTick(() => {
      //       if (!viewer.modalWindow.isPopover) {
      //         viewer.$refs.modal_viewer.loadInterface(_window.structure)
      //       } else {
      //         viewer.$refs.modal_viewer_popover.loadInterface(_window.structure)
      //       }
      //     })
      //   } else {
      //     console.warn('window does`t set', command)
      //   }
      // }
    },
    openQuickAddCardByCheckbox (quickAddCard, initialData = {}) {
      const h = this.$createElement
      let customClass = 'custom_scrollbar '
      if (quickAddCard.windowWidth) {
        customClass += `window_width_${quickAddCard.windowWidth}`
      }
      let me = this
      this.$msgbox({
        customClass: customClass,
        message: h('registry-card', {
          style: {
            height: quickAddCard.windowHeight || ''
          },
          props: {
            cardId: quickAddCard.cardId,
            registryId: quickAddCard.registryId,
            parentContext: null,
            model: {},
            quick: true,
            initialData: initialData
          },
          on: {
            'quick-add': async function (data) {
              let cardFast = await me.getFastCard(data, quickAddCard.registryId)
              me.openRegistryCard({
                registryId: quickAddCard.registryId,
                cardId: cardFast.id,
                cardName: cardFast.name,
                recordId: null,
                initialData: data,
                registry: {
                  addRecord: (recordid) => {
                    me.getModel()[quickAddCard.fieldId] = recordid
                    me.refreshComponents(command.card.componentsGuid)
                  },
                  updateRecord: () => {
                    me.refreshComponents(command.card.componentsGuid)
                  }
                }
              })
              me.$msgbox.close()
            },
            cancelChanges: function () {
              me.$msgbox.close()
            }
          },
          key: this.generateGuid() }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    },
    async getFastCard (recordData = null, registryId) {
      let data = await this.$http.post(`${this.$config.api}/registryservice/registry/${registryId}/card`,
        recordData, { hideNotification: true })
      return data.data[0]
    }
  }
}
</script>

<style scoped>
  .el-dropdown-link {
    cursor: pointer;
  }
</style>
